.input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 30px;
  padding: 2px 0;
}
.input[data-expanded='true'] {
  height: calc(5 * 20px);
}
.input:before,
.input:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  pointer-events: none;
  transition: border-color var(--fast);
}
.input:before {
  border: solid 2px transparent;
}
.input[data-expanded='true']:before {
  border-color: var(--off-white);
}
.input:after {
  border-bottom: solid 2px var(--black);
}
.input[data-focused='true']:after {
  border-color: var(--green);
}
input,
textarea {
  box-sizing: border-box;
  flex-basis: 0;
  min-width: 0;
  flex-grow: 1;
  height: 100%;
  padding: 0 7.5px;
  background: none;
  border: none;
  outline: none;
  resize: none;
  line-height: 20px;
}
input:focus,
textarea:focus {
  box-shadow: none;
}
.input_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
