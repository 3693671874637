@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700');

* {
  --green: #26a36c;
  --blue: #2196f3;
  --red: #e91e63;
  --black: #000000;
  --dark-gray: #606060;
  --gray: #a0a0a0;
  --light-gray: #e0e0e0;
  --off-white: #f0f0f0;
  --white: #ffffff;

  --shadow: 0 0 4px 0 #00000040;

  --slow: 0.5s ease;
  --fast: 0.25s ease;
  --very-fast: 0.1s ease;

  font-family: 'Poppins';
}
html {
  height: 100%;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 11pt;
  overflow-x: hidden;
  overflow-y: auto;
}
#root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
a {
  color: unset;
  transition: color var(--fast);
}
a:hover,
a:focus {
  color: var(--green);
}
p {
  margin: 20px 0;
}
table {
  border-collapse: collapse;
}
td {
  padding: 0;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
button,
input,
textarea {
  font-size: 11pt;
}
svg {
  flex-grow: 0;
  flex-shrink: 0;
}
*:focus {
  outline: none;
  box-shadow: inset 0 0 0 1px var(--green);
}
.size_tiny {
  font-size: 8pt;
}
.size_small {
  font-size: 10pt;
}
.size_normal {
  font-size: 11pt;
}
.size_medium {
  font-size: 13pt;
}
.size_large {
  font-size: 18pt;
}
.size_huge {
  font-size: 24pt;
}
.weight_medium {
  font-weight: 500;
}
.weight_semibold {
  font-weight: 600;
}
.flip_horizontal {
  transform: scaleX(-1);
}
.flip_vertical {
  transform: scaleY(-1);
}
.rotate_cw {
  transform: rotate(90deg);
}
.rotate_ccw {
  transform: rotate(-90deg);
}
.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}
*[data-shade='true'] {
  background: var(--off-white);
}
*[data-highlight='true'] {
  font-weight: 600;
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.controls + .controls {
  margin-top: 0;
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.info > * {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}
.info svg {
  margin-right: 10px;
}
