.alert {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30px;
  color: var(--gray);
}
.alert > svg {
  margin-right: 10px;
}
.alert[data-error='true'] {
  color: var(--red);
}
