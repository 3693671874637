.gene_search_result_multi {
  display: flex;
  align-items: center;
  height: 30px;
}
.gene_search_result_multi_query {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: italic;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
}
.gene_search_result_multi_query[data-expanded='true'] {
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 30px);
}
.gene_search_result_multi_results {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 100px - 30px - 1px - 1px);
  height: 100%;
}
.gene_search_result_multi_results > .td {
  width: calc((100% - 30px - 30px - 30px - 1px - 1px) / 6);
}
.gene_search_result_multi_check {
  width: 30px;
}
.gene_search_result_multi_expand {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

@media screen and (max-width: 660px) {
  .gene_search_result_multi_results > .td {
    width: calc((100% - 30px - 30px - 30px - 1px - 1px) / 3);
  }
  .gene_search_result_multi_results > .td + .td {
    display: none;
  }
}
