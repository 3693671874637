.search_results_single_alert {
  height: calc(2 * 30px);
}
.search_results_note {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
}
.search_results_note:not(:first-child) {
  margin-top: 10px;
}
.search_results_note:not(:last-child) {
  margin-bottom: 10px;
}
