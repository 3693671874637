.tooltip {
  box-sizing: border-box;
  position: absolute;
  padding: 5px 10px;
  color: var(--white);
  background: var(--black);
  z-index: 999;
  pointer-events: none;
}
.tooltip-enter {
  opacity: 0;
}
.tooltip-enter-active {
  opacity: 1;
}
.tooltip-exit {
  opacity: 1;
}
.tooltip-exit-active {
  opacity: 0;
}
.tooltip_table {
  color: var(--white);
}
.tooltip_table td:first-child {
  padding-right: 10px;
}
