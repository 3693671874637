.nav_button {
  position: relative;
  margin: 5px !important;
  padding: 3px 8px !important;
  text-decoration: none !important;
}
.nav_button > svg {
  margin-right: 8px !important;
}
.nav_button:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  opacity: 0;
  background: var(--green);
  transition: opacity var(--fast);
}
.nav_button[data-active='true']:after {
  opacity: 1;
}
