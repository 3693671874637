.page_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: var(--shadow);
}
.page_header_column {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 50px;
}
.page_header_column:nth-child(1) {
  justify-content: flex-start;
  padding: 0 10px;
}
.page_header_column:nth-child(2) {
  justify-content: center;
  flex-grow: 1;
}
.page_header_column:nth-child(3) {
  justify-content: flex-end;
  padding: 0 10px;
}

@media screen and (max-width: 660px) {
  .page_header_column:nth-child(1) {
    flex-grow: 1;
    order: 1;
  }
  .page_header_column:nth-child(2) {
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 0;
    height: unset;
    order: 3;
  }
  .page_header_column:nth-child(3) {
    flex-grow: 1;
    order: 2;
  }
  .page_header_column:first-child:last-child {
    justify-content: center;
  }
}
