.detail_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.detail_row > span {
  display: inline-block;
  word-break: break-word;
}
.detail_row > span:nth-child(1) {
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
}
.detail_row > span:nth-child(2) {
  flex-grow: 1;
}
