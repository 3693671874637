.feature {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 0 10px;
}
.thumbnail {
  width: 320px;
  max-width: 100%;
  background: var(--dark-gray);
}
.feature_button {
  margin: 0 !important;
}

@media screen and (max-width: 660px) {
  .feature {
    flex-direction: column;
  }
  .pane {
    width: 100%;
    padding: 0;
  }
  .pane_first {
    order: -1;
    margin-bottom: 20px;
  }
}
