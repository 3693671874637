#volcano {
  width: 100%;
  height: 600px;
}
.volcano_dot {
  cursor: pointer;
}
#volcano_dots .volcano_dot:hover {
  opacity: 1 !important;
  stroke: var(--black);
  stroke-width: 2;
}
.tick {
  font-size: 9pt;
}
#x_axis_label,
#y_axis_label {
  font-size: 12pt;
}
