#graph {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border: solid 1px var(--light-gray);
}
.graph_link_highlight {
  opacity: 0;
  transition: opacity var(--very-fast);
}
.graph_link_highlight:hover {
  opacity: 0.5;
}
.graph_node_highlight {
  opacity: 0;
  transition: opacity var(--very-fast);
}
.graph_node_highlight:hover {
  opacity: 0.5;
}
