@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700);
.tooltip {
  box-sizing: border-box;
  position: absolute;
  padding: 5px 10px;
  color: var(--white);
  background: var(--black);
  z-index: 999;
  pointer-events: none;
}
.tooltip-enter {
  opacity: 0;
}
.tooltip-enter-active {
  opacity: 1;
}
.tooltip-exit {
  opacity: 1;
}
.tooltip-exit-active {
  opacity: 0;
}
.tooltip_table {
  color: var(--white);
}
.tooltip_table td:first-child {
  padding-right: 10px;
}

main {
  flex-grow: 1;
}
main > * {
  margin: 40px auto;
  max-width: 800px;
}

.home_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  height: 300px;
  background: var(--black);
  color: var(--white);
}
.home_canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  -webkit-filter: contrast(125%);
          filter: contrast(125%);
}
.home_shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #000000ff 75px, #00000000 150px);
  z-index: 1;
}
.home_logo {
  margin-bottom: 5px;
}
.home_logo,
.home_title {
  z-index: 2;
}

.clickable {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}
.clickable {
  transition: color var(--fast), background var(--fast), opacity var(--fast),
    border var(--fast), border-color var(--fast);
}

.clickable[data-button][data-link][data-text][data-icon]:hover,
.clickable[data-button][data-link][data-text][data-icon]:focus {
  color: var(--green);
}
.clickable > svg:not(:first-child) {
  margin-left: 5px;
}
.clickable > svg:not(:last-child) {
  margin-right: 8px;
}
.clickable[data-button='true'] {
  min-width: 30px;
  min-height: 30px;
}
.clickable[data-button='true'][data-text='true'] {
  margin: 5px 10px;
  padding: 0 10px;
  border: solid 2px transparent;
  color: var(--green);
  font-weight: 500;
}
.clickable[data-button='true'][data-text='true']:hover,
.clickable[data-button='true'][data-text='true']:focus {
  border-color: var(--green);
  box-shadow: none;
}
.clickable[data-button='true'][data-text='false'] {
  color: var(--black);
}
.clickable[data-link='true'] {
  display: inline-block;
  color: var(--black);
  text-decoration: underline;
}

.banner {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.banner > a {
  margin-top: 20px !important;
}

.feature {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 0 10px;
}
.thumbnail {
  width: 320px;
  max-width: 100%;
  background: var(--dark-gray);
}
.feature_button {
  margin: 0 !important;
}

@media screen and (max-width: 660px) {
  .feature {
    flex-direction: column;
  }
  .pane {
    width: 100%;
    padding: 0;
  }
  .pane_first {
    order: -1;
    margin-bottom: 20px;
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: var(--shadow);
}

.horizontal_line {
  content: '';
  width: 100%;
  border: none;
  height: 1px;
  background: var(--light-gray);
  z-index: -1;
}


.logo_small {
  color: var(--green);
  margin-right: 10px !important;
}
.title_text {
  color: var(--green);
}

.nav_button {
  position: relative;
  margin: 5px !important;
  padding: 3px 8px !important;
  text-decoration: none !important;
}
.nav_button > svg {
  margin-right: 8px !important;
}
.nav_button:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  opacity: 0;
  background: var(--green);
  transition: opacity var(--fast);
}
.nav_button[data-active='true']:after {
  opacity: 1;
}


.popup_overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #00000080;
  z-index: 99;
}
.popup_content {
  background: var(--white);
  position: absolute;
  box-shadow: var(--shadow);
  z-index: 100;
}


.model_item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 40px;
}
.model_summary {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: calc(100% - 30px);
  padding-right: 5px;
}
.model_summary > * {
  line-height: 1.3em;
}


.model_select_button {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: var(--off-white);
  border-radius: 100%;
}
.model_select_button:hover,
.model_select_button:focus {
  box-shadow: none;
  background: var(--green);
  color: var(--white) !important;
}
.model_select_popup {
  width: 250px;
  min-height: calc((1 * 40px) + ((1 - 1) * 1px));
  max-height: calc((5 * 40px) + ((5 - 1) * 1px));
  overflow-x: hidden;
  overflow-y: auto;
}
.model_alert {
  height: 40px;
}


.page_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: var(--shadow);
}
.page_header_column {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 50px;
}
.page_header_column:nth-child(1) {
  justify-content: flex-start;
  padding: 0 10px;
}
.page_header_column:nth-child(2) {
  justify-content: center;
  flex-grow: 1;
}
.page_header_column:nth-child(3) {
  justify-content: flex-end;
  padding: 0 10px;
}

@media screen and (max-width: 660px) {
  .page_header_column:nth-child(1) {
    flex-grow: 1;
    order: 1;
  }
  .page_header_column:nth-child(2) {
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 0;
    height: unset;
    order: 3;
  }
  .page_header_column:nth-child(3) {
    flex-grow: 1;
    order: 2;
  }
  .page_header_column:first-child:last-child {
    justify-content: center;
  }
}

section {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
section[data-expanded='false'] {
  display: none;
}
.section_header {
  box-sizing: border-box;
  color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: color var(--fast);
}
.section_header:hover {
  color: var(--green);
}
.section_header > svg {
  margin-right: 10px;
}
.section_header:before,
.section_header:after {
  content: '';
  flex-grow: 1;
  background: var(--light-gray);
  height: 1px;
  transition: background var(--fast);
}
.section_header:hover:before,
.section_header:hover:after {
  background: var(--green);
}
.section_header:before {
  margin-right: 20px;
}
.section_header:after {
  margin-left: 20px;
}

.input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 30px;
  padding: 2px 0;
}
.input[data-expanded='true'] {
  height: calc(5 * 20px);
}
.input:before,
.input:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  pointer-events: none;
  transition: border-color var(--fast);
}
.input:before {
  border: solid 2px transparent;
}
.input[data-expanded='true']:before {
  border-color: var(--off-white);
}
.input:after {
  border-bottom: solid 2px var(--black);
}
.input[data-focused='true']:after {
  border-color: var(--green);
}
input,
textarea {
  box-sizing: border-box;
  flex-basis: 0;
  min-width: 0;
  flex-grow: 1;
  height: 100%;
  padding: 0 7.5px;
  background: none;
  border: none;
  outline: none;
  resize: none;
  line-height: 20px;
}
input:focus,
textarea:focus {
  box-shadow: none;
}
.input_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.search_bar {
  margin-bottom: 10px;
}

.table {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}
.tr {
  display: flex;
  align-items: center;
  height: 30px;
}
.th,
.td {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 30px;
}
.th {
  font-weight: 500;
  transition: color var(--fast);
  -webkit-user-select: none;
          user-select: none;
}
.th > svg {
  flex-shrink: 0;
  margin-left: 3px;
}
.table[data-sortable='true'] .th:hover {
  color: var(--green);
}
.th[data-disabled='true'] {
  cursor: unset;
}
.th[data-padded='true'],
.td[data-padded='true'] {
  padding: 0 5px;
}
.table[data-freeze-row='true'] .thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 2;
}
.table[data-freeze-col='true'] .th:first-child,
.table[data-freeze-col='true'] .td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: var(--white);
  z-index: 1;
}


.search_results_single_alert {
  height: calc(2 * 30px);
}
.search_results_note {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
}
.search_results_note:not(:first-child) {
  margin-top: 10px;
}
.search_results_note:not(:last-child) {
  margin-bottom: 10px;
}

.vertical_line {
  content: '';
  width: 1px;
  height: calc(100% - 5px - 5px);
  margin: 5px 0;
  background: var(--light-gray);
  z-index: -1;
}

.gene_search_result_multi {
  display: flex;
  align-items: center;
  height: 30px;
}
.gene_search_result_multi_query {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: italic;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
}
.gene_search_result_multi_query[data-expanded='true'] {
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 30px);
}
.gene_search_result_multi_results {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 100px - 30px - 1px - 1px);
  height: 100%;
}
.gene_search_result_multi_results > .td {
  width: calc((100% - 30px - 30px - 30px - 1px - 1px) / 6);
}
.gene_search_result_multi_check {
  width: 30px;
}
.gene_search_result_multi_expand {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

@media screen and (max-width: 660px) {
  .gene_search_result_multi_results > .td {
    width: calc((100% - 30px - 30px - 30px - 1px - 1px) / 3);
  }
  .gene_search_result_multi_results > .td + .td {
    display: none;
  }
}


.gene_search_results_multi_alert {
  height: calc(1 * 30px);
  padding: 0 10px;
}


.alert {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30px;
  color: var(--gray);
}
.alert > svg {
  margin-right: 10px;
}
.alert[data-error='true'] {
  color: var(--red);
}







.rc-slider {
  box-sizing: border-box;
  position: relative;
  height: 35px;
  margin: 15px;
  touch-action: none;
  cursor: pointer;
}
.rc-slider * {
  box-sizing: border-box;
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 5px;
  background: var(--light-gray);
}
.slider[data-reverse='true'] .rc-slider-rail {
  background: var(--green);
}
.rc-slider-track {
  position: absolute;
  height: 5px;
  left: 0;
  top: 5px;
  background: var(--green);
}
.slider[data-reverse='true'] .rc-slider-track {
  background: var(--light-gray);
}
.rc-slider-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  border-radius: 15px;
  background: var(--white);
  border: solid 2px var(--green);
}
.rc-slider-mark {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}


#graph {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border: solid 1px var(--light-gray);
}
.graph_link_highlight {
  opacity: 0;
  transition: opacity var(--very-fast);
}
.graph_link_highlight:hover {
  opacity: 0.5;
}
.graph_node_highlight {
  opacity: 0;
  transition: opacity var(--very-fast);
}
.graph_node_highlight:hover {
  opacity: 0.5;
}







.activities_header,
.activities_row {
  display: flex;
}
.activities_header > *,
.activities_row > * > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
}
.activities_header > :nth-child(1),
.activities_row > :nth-child(1) {
  width: 60px;
}
.activities_header > :nth-child(2),
.activities_row > :nth-child(2) {
  width: calc((100% - 60px) * 0.25);
}
.activities_header > :nth-child(3),
.activities_row > :nth-child(3) {
  width: calc((100% - 60px) * 0.75);
}
.activities_header > :nth-child(2),
.activities_row > :nth-child(2) > * {
  box-sizing: border-box;
  padding: 0 5px;
  justify-content: flex-start;
}
.activities_row > :nth-child(3) {
  overflow-x: auto;
  overflow-y: unset;
}
.heatmap_cell {
  cursor: pointer;
}
#heatmap:hover .heatmap_cell {
  opacity: 0.35;
}
.heatmap_cell:hover {
  opacity: 1 !important;
}

#heatmap_scale_gradient,
#heatmap_scale_minmax {
  width: 100%;
}
#heatmap_scale_gradient {
  height: 10px;
  background: linear-gradient(
    to right,
    #6e40aa,
    #417de0,
    #1ac8c1,
    #41f373,
    #aff05b
  );
}
#heatmap_scale_minmax {
  display: flex;
  justify-content: space-between;
}

.experiment_info > div {
  margin-bottom: 10px;
}





.input input[type="number"] {
  text-align: center;
}

#volcano_filters {
  display: flex;
  justify-content: center;
  align-items: center;
}
#volcano_filters > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
#volcano_filters > * > * {
  margin: 0 5px;
}
.volcano_search {
  width: 180px;
}
.volcano_cutoff {
  width: 70px;
}

@media screen and (max-width: 660px) {
  #volcano_filters {
    flex-direction: column;
  }
  #volcano_filters > * {
    width: 100%;
  }
  #volcano_filters > *:first-child {
    margin-bottom: 10px;
  }
}

#volcano {
  width: 100%;
  height: 600px;
}
.volcano_dot {
  cursor: pointer;
}
#volcano_dots .volcano_dot:hover {
  opacity: 1 !important;
  stroke: var(--black);
  stroke-width: 2;
}
.tick {
  font-size: 9pt;
}
#x_axis_label,
#y_axis_label {
  font-size: 12pt;
}












.barcode {
  width: 100%;
  height: 100%;
}
.barcode_button {
  width: 150px;
  height: 30px;
  border: solid 2px transparent;
  transition: border-color var(--fast);
}
.barcode_button:hover,
.barcode_button:focus {
  border-color: var(--green);
  box-shadow: none;
}










.help_header {
  margin-top: 40px;
}
.help_centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.help_centered > * {
  margin: 0 20px;
}
.help_link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.help_image {
  margin: 20px;
}
.cite_frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cite_box {
  padding: 20px;
  flex-grow: 1;
  border: dashed 2px var(--light-gray);
}
.cite_box > *:not(:last-child) {
  margin-bottom: 5px;
}
.altmetric,
.altmetric_small {
  flex-grow: 0;
  flex-shrink: 0;
}
.altmetric > *,
.altmetric img {
  width: 50px;
  height: 50px;
  margin: 10px;
}
.altmetric_small > *,
.altmetric_small img  {
  width: 30px;
  height: 30px;
  margin: 5px;
}

@media screen and (max-width: 660px) {
  .cite_frame {
    flex-direction: column;
  }
}

.detail_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.detail_row > span {
  display: inline-block;
  word-break: break-word;
}
.detail_row > span:nth-child(1) {
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
}
.detail_row > span:nth-child(2) {
  flex-grow: 1;
}






* {
  --green: #26a36c;
  --blue: #2196f3;
  --red: #e91e63;
  --black: #000000;
  --dark-gray: #606060;
  --gray: #a0a0a0;
  --light-gray: #e0e0e0;
  --off-white: #f0f0f0;
  --white: #ffffff;

  --shadow: 0 0 4px 0 #00000040;

  --slow: 0.5s ease;
  --fast: 0.25s ease;
  --very-fast: 0.1s ease;

  font-family: 'Poppins';
}
html {
  height: 100%;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 11pt;
  overflow-x: hidden;
  overflow-y: auto;
}
#root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
a {
  color: unset;
  transition: color var(--fast);
}
a:hover,
a:focus {
  color: var(--green);
}
p {
  margin: 20px 0;
}
table {
  border-collapse: collapse;
}
td {
  padding: 0;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
button,
input,
textarea {
  font-size: 11pt;
}
svg {
  flex-grow: 0;
  flex-shrink: 0;
}
*:focus {
  outline: none;
  box-shadow: inset 0 0 0 1px var(--green);
}
.size_tiny {
  font-size: 8pt;
}
.size_small {
  font-size: 10pt;
}
.size_normal {
  font-size: 11pt;
}
.size_medium {
  font-size: 13pt;
}
.size_large {
  font-size: 18pt;
}
.size_huge {
  font-size: 24pt;
}
.weight_medium {
  font-weight: 500;
}
.weight_semibold {
  font-weight: 600;
}
.flip_horizontal {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.flip_vertical {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
.rotate_cw {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rotate_ccw {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}
*[data-shade='true'] {
  background: var(--off-white);
}
*[data-highlight='true'] {
  font-weight: 600;
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.controls + .controls {
  margin-top: 0;
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.info > * {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}
.info svg {
  margin-right: 10px;
}

