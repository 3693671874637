.barcode {
  width: 100%;
  height: 100%;
}
.barcode_button {
  width: 150px;
  height: 30px;
  border: solid 2px transparent;
  transition: border-color var(--fast);
}
.barcode_button:hover,
.barcode_button:focus {
  border-color: var(--green);
  box-shadow: none;
}
