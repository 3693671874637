.model_select_button {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: var(--off-white);
  border-radius: 100%;
}
.model_select_button:hover,
.model_select_button:focus {
  box-shadow: none;
  background: var(--green);
  color: var(--white) !important;
}
.model_select_popup {
  width: 250px;
  min-height: calc((1 * 40px) + ((1 - 1) * 1px));
  max-height: calc((5 * 40px) + ((5 - 1) * 1px));
  overflow-x: hidden;
  overflow-y: auto;
}
.model_alert {
  height: 40px;
}
