.clickable {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}
.clickable {
  transition: color var(--fast), background var(--fast), opacity var(--fast),
    border var(--fast), border-color var(--fast);
}

.clickable[data-button][data-link][data-text][data-icon]:hover,
.clickable[data-button][data-link][data-text][data-icon]:focus {
  color: var(--green);
}
.clickable > svg:not(:first-child) {
  margin-left: 5px;
}
.clickable > svg:not(:last-child) {
  margin-right: 8px;
}
.clickable[data-button='true'] {
  min-width: 30px;
  min-height: 30px;
}
.clickable[data-button='true'][data-text='true'] {
  margin: 5px 10px;
  padding: 0 10px;
  border: solid 2px transparent;
  color: var(--green);
  font-weight: 500;
}
.clickable[data-button='true'][data-text='true']:hover,
.clickable[data-button='true'][data-text='true']:focus {
  border-color: var(--green);
  box-shadow: none;
}
.clickable[data-button='true'][data-text='false'] {
  color: var(--black);
}
.clickable[data-link='true'] {
  display: inline-block;
  color: var(--black);
  text-decoration: underline;
}
