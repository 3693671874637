.activities_header,
.activities_row {
  display: flex;
}
.activities_header > *,
.activities_row > * > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
}
.activities_header > :nth-child(1),
.activities_row > :nth-child(1) {
  width: 60px;
}
.activities_header > :nth-child(2),
.activities_row > :nth-child(2) {
  width: calc((100% - 60px) * 0.25);
}
.activities_header > :nth-child(3),
.activities_row > :nth-child(3) {
  width: calc((100% - 60px) * 0.75);
}
.activities_header > :nth-child(2),
.activities_row > :nth-child(2) > * {
  box-sizing: border-box;
  padding: 0 5px;
  justify-content: flex-start;
}
.activities_row > :nth-child(3) {
  overflow-x: auto;
  overflow-y: unset;
}
.heatmap_cell {
  cursor: pointer;
}
#heatmap:hover .heatmap_cell {
  opacity: 0.35;
}
.heatmap_cell:hover {
  opacity: 1 !important;
}
