.table {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}
.tr {
  display: flex;
  align-items: center;
  height: 30px;
}
.th,
.td {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 30px;
}
.th {
  font-weight: 500;
  transition: color var(--fast);
  user-select: none;
}
.th > svg {
  flex-shrink: 0;
  margin-left: 3px;
}
.table[data-sortable='true'] .th:hover {
  color: var(--green);
}
.th[data-disabled='true'] {
  cursor: unset;
}
.th[data-padded='true'],
.td[data-padded='true'] {
  padding: 0 5px;
}
.table[data-freeze-row='true'] .thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 2;
}
.table[data-freeze-col='true'] .th:first-child,
.table[data-freeze-col='true'] .td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: var(--white);
  z-index: 1;
}
